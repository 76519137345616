<template>
  <div class="header">
    <section class="header-top row">
      <div class="left col-12 col-lg">
        {{
          $t(`${$store.getters.getTranslationKeyForAdmin}.calendar.calendar`)
        }}
      </div>
      <div class="central col-12 col-lg mt-3 mt-md-0">
        <ButtonElement @click="handleCalendarView('grid')" orange link>
          <span class="button-color" :class="{ active: view === 'grid' }">
            {{
              $t(
                `${$store.getters.getTranslationKeyForAdmin}.calendar.gridView`
              )
            }}
          </span>
        </ButtonElement>
        <ButtonElement @click="handleCalendarView('list')" grey link>
          <span class="button-color" :class="{ active: view === 'list' }">
            {{
              $t(
                `${$store.getters.getTranslationKeyForAdmin}.calendar.listView`
              )
            }}
          </span>
        </ButtonElement>
      </div>
      <div class="right col-12 col-lg">
        <!-- <SelectElement
          :placeholder="
            $t(`${$store.getters.getTranslationKeyForAdmin}.calendar.location`)
          "
          background-color="grey"
          :options="$store.getters.companyLocationsOptions"
          v-model="selectedLocation"
          multi-select
          @dropdown-closed="
            $store.dispatch('fetchAppointments', {
              location: selectedLocation.join(','),
            })
          "
          class="location-select"
        /> -->
      </div>
    </section>
    <section class="header-bottom row mt-3">
      <div
        class="left col-12 col-lg justify-content-center justify-content-lg-start"
      >
        <ButtonElement
          @click="$store.commit('setShowAppointmentCreateModal', true)"
          orange
        >
          <span class="button-icon">
            <PlusIcon width="22px" height="22px" />
            {{
              $t(
                `${$store.getters.getTranslationKeyForAdmin}.calendar.newAppointment`
              )
            }}
          </span>
        </ButtonElement>
      </div>
      <div class="central col-12 col-lg mt-3 mt-lg-0">
        <span class="nav-icon">
          <ButtonElement @click="vuecal.previous()" link>
            <CalendarCarrotLeftIcon rotate="180deg" color="#2b2b2b" />
          </ButtonElement>
        </span>
        <span class="nav-title">{{ calendarTitle }}</span>
        <span class="nav-icon">
          <ButtonElement @click="vuecal.next()" link>
            <CalendarCarrotLeftIcon color="#2b2b2b" />
          </ButtonElement>
        </span>
      </div>
      <div
        class="right col-12 col-lg mt-3 mt-xl-0 justify-content-center justify-content-lg-end"
      >
        <SelectElement
          :placeholder="$t(`serviceProvider.calendar.period`)"
          background-color="grey"
          :options="$store.getters.periodOptions"
          :value="period"
          @input="
            $router.push({
              name: 'Calendar',
              params: { view, period: $event },
            })
          "
        />
        <SelectElement
          v-model="selectedStaff"
          :placeholder="
            $t(`${$store.getters.getTranslationKeyForAdmin}.calendar.allStaff`)
          "
          background-color="grey"
          :options="$store.getters.staffUsersOptions"
          multi-select
          @dropdown-closed="
            $store.dispatch('fetchAppointments', {
              staff: selectedStaff.join(','),
            })
          "
          class="staff-select"
        />
      </div>
    </section>
  </div>
</template>

<script>
import ButtonElement from '../../../components/FormControl/ButtonElement.vue';
import SelectElement from '../../../components/FormControl/SelectElement/SelectElement.vue';
import CalendarCarrotLeftIcon from '../../../components/Svgs/Carrots/CalendarCarrotLeftIcon.vue';
import router from '../../../router';
import PlusIcon from '../../../components/Svgs/PlusIcon.vue';
import { computed } from '@vue/composition-api';
import store from '../../../store';

export default {
  setup(props) {
    const selectedStaff = computed({
      get: () => store.getters.selectedStaff,
      set: value => store.commit('setSelectedStaff', value),
    });
    const selectedLocation = computed({
      get: () => store.getters.selectedLocation,
      set: value => store.commit('setSelectedLocation', value),
    });
    const handleCalendarView = view => {
      router.push({
        name: 'Calendar',
        params: { view, period: props.period },
      });
    };

    return {
      handleCalendarView,
      selectedStaff,
      selectedLocation,
    };
  },
  components: {
    PlusIcon,
    CalendarCarrotLeftIcon,
    SelectElement,
    ButtonElement,
  },
  props: {
    vuecal: {
      type: Object,
      default: () => ({}),
    },
    calendarTitle: {
      type: String,
      default: '',
    },
    period: {
      type: String,
      default: 'week',
    },
    view: {
      type: String,
      default: 'grid',
    },
  },
};
</script>

<style lang="scss" scoped>
.header-top {
  // display: grid;
  // grid-template-columns: repeat(3, 1fr);

  > .left {
    font-style: normal;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.2;
    color: $hty-orange;
    display: inline-flex;
    align-items: center;
  }
  > .central {
    display: flex;
    align-items: center;
    justify-content: center;

    > :last-child {
      margin-left: 2rem;
    }
  }

  > .right {
    display: flex;
    justify-content: flex-end;
    > :first-child {
      width: 130px;
    }
  }
}
.header-bottom {
  // display: grid;
  // grid-template-columns: repeat(3, 1fr);
  // margin-top: 1rem;

  > .left {
    display: flex;
    align-items: center;
  }

  > .central {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > .right {
    display: flex;
    // justify-content: flex-end;

    > * {
      width: 130px;
    }

    > :last-child {
      margin-left: 1rem;
    }
  }
}
.nav-title {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.2;
  color: $hty-black;
  margin: 0 1rem;
  display: flex;
  align-items: center;
}
.nav-icon {
  display: flex;
  align-items: center;
}
.button-color {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.2;
  color: $hty-dark-grey;

  &.active {
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.2;
    color: $hty-orange;
  }
}
.button-icon {
  display: inline-flex;
  align-items: center;

  > :first-child {
    margin-right: 0.5rem;
  }
}
.location-select {
  ::v-deep .select-dropdown-container {
    width: 200px;
    left: -70px;
  }
}
.staff-select {
  ::v-deep .select-dropdown-container {
    width: 200px;
    left: -70px;
  }
}
</style>
