<template>
  <div class="pb-5" v-if="!$store.getters.isAdminUserVerified">
    <div class="text-center p-3" style="background: rgba(229, 132, 19, 0.15)">
      <h3 style="color: #e58413">Verifizierung ausstehend</h3>
      <p class="mb-0" style="color: #999999">
        Um Ihr Unternehmen zu verifizieren, reichen Sie uns bitte die benötigten
        Dokumente ein
      </p>
    </div>
    <div class="mt-5 pt-5">
      <div class="d-flex justify-content-center">
        <img :src="require('@/assets/svg/docs_verification.svg')" />
      </div>
      <div class="d-flex justify-content-center mt-4">
        <ButtonElement :anchor="{ path: '/settings/company#docs' }" orange>
          + Dokumente hochladen
        </ButtonElement>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonElement from '../../components/FormControl/ButtonElement.vue';

export default {
  components: { ButtonElement },
};
</script>
