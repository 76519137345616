<template>
  <div class="modal-custom">
    <!-- Close button -->
    <div class="close">
      <ButtonElement @click="$emit('cancel')" link grey>
        <XIcon />
      </ButtonElement>
    </div>

    <!-- Header -->
    <div class="header">
      <template v-if="!data">
        {{ $t('serviceProvider.calendar.newAppointment') }}
      </template>
      <template v-else>
        {{ $t('serviceProvider.calendar.editAppointment') }}
      </template>
    </div>

    <!-- Content -->
    <div class="content">
      <!-- Service select -->
      <div
        v-if="$store.getters.isAdminServiceProvider && !data"
        class="content-row"
      >
        <span class="left">
          <label class="label">
            {{ $t('serviceProvider.calendar.service') }}
          </label>
          <SelectElement
            v-model="formData.service"
            :options="$store.getters.companyServicesOptions"
            class="service-select"
          />
        </span>
      </div>

      <!-- Date -->
      <div class="content-row">
        <span class="left">
          <label class="label">
            {{ $t('serviceProvider.calendar.date') }}
          </label>
          <DateRangePicker
            v-model="formData.dateStart"
            :ranges="false"
            single-date-picker
            auto-apply
            class="date-picker"
            :min-date="new Date(new Date().setHours(0, 0, 0, 0))"
            :locale-data="{ firstDay: 1 }"
          >
            <template #input="{ startDate }">
              {{ dateFormat(startDate) }}
            </template>
          </DateRangePicker>
        </span>
      </div>

      <!-- Time start/end -->
      <div class="content-row">
        <span class="left">
          <label class="label">
            {{ $t('serviceProvider.calendar.timeStart') }}
          </label>
          <TimePicker
            v-model="formData.timeStart"
            :minute-interval="5"
            :hour-range="timeStartRange.hour"
            :minute-range="timeStartRange.minute"
            hide-disabled-items
            hide-clear-button
            close-on-complete
            auto-scroll
            class="time-picker"
            placeholder="Uhrzeit wählen"
          />
        </span>
        <span class="right">
          <label class="label">
            {{ $t('serviceProvider.calendar.timeEnd') }}
          </label>
          <TimePicker
            v-model="formData.timeEnd"
            :minute-interval="5"
            :hour-range="timeEndRange.hour"
            :minute-range="timeEndRange.minute"
            hide-disabled-items
            hide-clear-button
            close-on-complete
            auto-scroll
            class="time-picker"
            placeholder="Uhrzeit wählen"
          />
        </span>
      </div>

      <!-- Staff -->
      <div v-if="$store.getters.isAdminServiceProvider" class="content-row">
        <span class="left">
          <label class="label">
            {{ $t('serviceProvider.calendar.staff') }}
          </label>
          <SelectElement
            v-model="formData.staffId"
            :options="staffUsersOptions"
            placeholder="Auswählen"
          />
        </span>
      </div>

      <!-- Client(s) -->
      <div class="content-row">
        <span class="left">
          <label class="label">
            {{ $t('serviceProvider.calendar.client') }}
          </label>
          <SearchElement
            v-if="$store.getters.isAdminServiceProvider"
            v-model="clientName"
            @input="handleServiceCall"
            @selected="handleClientSelection"
            :data="$store.getters.clients"
            :loading="$store.getters.loading"
            placeholder="Suche"
          />
          <MultiInput
            v-if="$store.getters.isAdminRetailer"
            :value="clientName"
            :recipients="clientNames"
            @keypress="handleKeyPress($event)"
            @input="handleInput($event)"
            @removeRecipient="removeClient($event)"
            :search-data="$store.getters.clients"
            :loading="$store.getters.loading"
            @selected="handleMultipleClientsSelection"
          />
        </span>
      </div>

      <!-- Phone number -->
      <div
        v-if="$store.getters.isAdminServiceProvider && !data"
        class="content-row"
      >
        <span class="left">
          <label class="label">
            {{ $t('serviceProvider.calendar.phoneNumber') }}
          </label>
          <InputElement v-model="formData.phoneNumber" />
        </span>
      </div>

      <!-- Notes -->
      <div
        v-if="$store.getters.isAdminServiceProvider && !data"
        class="content-row"
      >
        <span class="left">
          <label class="label">
            {{ $t('serviceProvider.calendar.notes') }}
          </label>
          <TextareaElement v-model="formData.note" />
        </span>
      </div>
    </div>

    <!-- ResponseAlert -->
    <ResponseAlert
      v-if="$store.getters.errorResponse.action == 'appointmentEdit'"
      :response="$store.getters.errorResponse.data"
      class="mt-2"
    ></ResponseAlert>

    <!-- Footer -->
    <div class="footer mt-3">
      <ButtonElement @click="$emit('cancel')" orange-secondary>
        {{ cancelButtonText }}
      </ButtonElement>
      <ButtonElement
        @click="handleConfirm"
        :loading="$store.getters.loading"
        orange
      >
        {{ confirmButtonText }}
      </ButtonElement>
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref } from '@vue/composition-api';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import { debounce, filter, uniqBy } from 'lodash';
import TimePicker from 'vue2-timepicker/src/vue-timepicker.vue';
import ButtonElement from '../../FormControl/ButtonElement.vue';
import SelectElement from '../../FormControl/SelectElement/SelectElement.vue';
import TextareaElement from '../../FormControl/TextareaElement.vue';
import InputElement from '../../FormControl/InputElement.vue';
import { intlShortDateFormat } from '../../../config/intl';
import SearchElement from '../../Search/SearchElement.vue';
import store from '../../../store';
import { dateFormat as formatDate } from '../../../utils/dateTimeHelpers';
import { fullName } from '../../../utils/helpers';
import MultiInput from '../../FormControl/MultiInput/MultiInput.vue';
import moment from 'moment';

export default {
  setup(props, { emit }) {
    store.commit('setClients', []);
    store.commit('setErrorResponse', {});

    const formData = reactive({
      service: '',
      dateStart: {
        startDate: new Date(),
      },
      timeStart: '',
      timeEnd: '',
      clientId: [],
      phoneNumber: '',
      staffId: '',
      note: '',
    });
    const clientName = ref('');
    const clientNames = ref([]);

    if (props.data) {
      formData.id = props.data.id;
      formData.dateStart = {
        startDate: new Date(props.data.dateStart),
      };
      formData.timeStart = props.data.timeStart.slice(0, 5);
      formData.timeEnd = props.data.timeEnd.slice(0, 5);

      if (props.data.companyUser.type == 'service_provider') {
        formData.service = props.data.serviceData.id;
        formData.staffId = props.data.staffId;
        formData.phoneNumber = props.data.phoneNumber;
        formData.note = props.data.note;
        formData.clientId = [props.data.clientId];
        clientName.value = props.data.clients
          ? fullName(props.data.clients)
          : props.data.clientId;
      } else if (props.data.companyUser.type == 'retailer') {
        props.data.clients.forEach(c => {
          formData.clientId.push(c.id || c.name);
          clientNames.value.push(c.name || fullName(c));
        });
      }
    }

    const createAppointmentPayload = computed(() => {
      if (store.getters.userInfo.type === 'service_provider') {
        return {
          ...formData,
          dateStart: formatDate(formData.dateStart.startDate),
        };
      } else {
        return {
          id: formData.id,
          dateStart: formatDate(formData.dateStart.startDate),
          timeStart: formData.timeStart,
          timeEnd: formData.timeEnd,
          clientId: formData.clientId,
        };
      }
    });

    const dateFormat = date => {
      return date ? intlShortDateFormat.format(date) : '';
    };

    const handleServiceCall = debounce(value => {
      if (store.getters.isAdminServiceProvider) {
        formData.clientId = [];
      }

      store.dispatch('searchClients', { search: value });
    }, 500);

    const handleClientSelection = ([user]) => {
      clientName.value = fullName(user);
      formData.clientId = [user.id];
      formData.phoneNumber = user.phoneNumber;
    };
    const handleMultipleClientsSelection = user => {
      if (!formData.clientId.includes(user.id)) {
        clientNames.value = [...clientNames.value, fullName(user)];
        formData.clientId = [...formData.clientId, user.id];
      }
      clientName.value = '';
    };

    const handleConfirm = () => {
      if (!formData.clientId.length && clientName.value) {
        formData.clientId.push(clientName.value);
      }

      emit('confirm', createAppointmentPayload.value);
    };

    const handleKeyPress = key => {
      if (key === ',' || key === 'Enter') {
        if (clientName.value && !clientNames.value.includes(clientName.value)) {
          clientNames.value.push(clientName.value);
          formData.clientId.push(clientName.value);
        }

        clientName.value = '';
      }
    };
    const handleInput = value => {
      if (value !== ',' && value !== ' ') {
        handleServiceCall(value);
        clientName.value = value;
      }
    };
    const removeClient = async value => {
      if (formData.clientId.includes(value)) {
        formData.clientId = formData.clientId.filter(name => name !== value);
        clientNames.value = clientNames.value.filter(name => name !== value);
      } else {
        try {
          await store.dispatch('searchClients', {
            search: value.substring(value.indexOf(' ')),
          });
          const idToRemove = store.getters.clients.find(
            client => fullName(client) === value
          ).id;
          const index = formData.clientId.findIndex(cl => cl === idToRemove);
          formData.clientId.splice(index, 1);
          clientNames.value = clientNames.value.filter(name => name !== value);
        } catch (e) {
          console.log('failed to fetch clients and to effectively remove item');
        }
      }
    };

    return {
      formData,
      dateFormat,
      handleServiceCall,
      handleClientSelection,
      createAppointmentPayload,
      clientName,
      clientNames,
      handleKeyPress,
      handleInput,
      removeClient,
      handleMultipleClientsSelection,
      handleConfirm,
    };
  },
  computed: {
    timeStartRange() {
      const isToday = this.isToday(this.formData.dateStart.startDate);
      const startHour = this.formData.timeStart.split(':')[0];

      const hourRange = [];
      for (let i = 0; i < 24; ++i) {
        if (isToday && i < moment().format('HH')) {
          continue;
        }

        hourRange.push(i);
      }

      const minuteRange = [];
      for (let i = 0; i < 60; i += 5) {
        if (
          isToday &&
          startHour == moment().format('HH') &&
          i < moment().format('mm')
        ) {
          continue;
        }

        minuteRange.push(i);
      }

      return {
        hour: hourRange,
        minute: minuteRange,
      };
    },

    timeEndRange() {
      if (!this.formData.timeStart) {
        return {
          hour: [],
          minute: [],
        };
      }

      const timeStart = this.formData.timeStart.split(':');
      const timeEnd = this.formData.timeEnd.split(':');

      if (timeStart[0] == 'HH' || timeStart[1] == 'mm') {
        return {
          hour: [],
          minute: [],
        };
      }

      const hourRange = [];
      for (let i = 0; i < 24; ++i) {
        if (i < timeStart[0]) {
          continue;
        }

        hourRange.push(i);
      }

      const minuteRange = [];
      for (let i = 0; i < 60; i += 5) {
        if (timeEnd[0] == timeStart[0] && i <= timeStart[1]) {
          continue;
        }

        minuteRange.push(i);
      }

      return {
        hour: hourRange,
        minute: minuteRange,
      };
    },
    staffUsersOptions() {
      let staff = [];
      if (this.formData.service) {
        staff = uniqBy(
          filter(this.$store.getters.companyServices, service => {
            return this.formData.service === service.id;
          })[0].staff,
          'id'
        ).map(item => ({
          label: `${item.firstName} ${item.lastName}`,
          value: item.id,
        }));
      } else {
        staff = this.$store.getters.staffUsersOptions;
      }
      return staff;
    },
  },
  watch: {
    'formData.timeStart': function () {
      const serviceId = this.formData.service;
      const timeStart = this.formData.timeStart.split(':');

      if (!serviceId || timeStart[0] == 'HH' || timeStart[1] == 'mm') {
        return;
      }

      const service = this.$store.getters.companyServices.find(
        s => s.id == serviceId
      );

      const timeEnd = moment();
      timeEnd.set('hour', timeStart[0]);
      timeEnd.set('minute', timeStart[1]);
      timeEnd.add(service.duration, 'minutes');

      if (timeEnd.format('dd') != moment().format('dd')) {
        timeEnd.set('hour', 23);
        timeEnd.set('minute', 55);
      }

      this.formData.timeEnd = timeEnd.format('HH:mm');
    },
  },
  methods: {
    isToday(someDate) {
      const today = new Date();
      return (
        someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
      );
    },
  },
  components: {
    MultiInput,
    SearchElement,
    InputElement,
    TextareaElement,
    SelectElement,
    ButtonElement,
    DateRangePicker,
    TimePicker,
  },
  props: {
    data: {
      type: Object,
      required: false,
    },
    confirmButtonText: {
      type: String,
      default: 'Speichern',
    },
    cancelButtonText: {
      type: String,
      default: 'Abbrechen',
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-custom {
  max-width: 450px;
  position: relative;
  padding: 2rem;
}
.close {
  position: absolute;
  top: 10px;
  right: 20px;

  ::v-deep path {
    stroke: $hty-black;
  }
}
.date-picker {
  width: 100%;
  color: $hty-dark-grey;

  ::v-deep .reportrange-text {
    border: $hty-border;
    height: 44px;
    border-radius: $hty-radius;
    display: inline-flex;
    align-items: center;
    // justify-content: center;
  }
}
.time-picker {
  width: 100%;

  ::v-deep .display-time {
    color: $hty-dark-grey;
    width: 100%;
    border: $hty-border;
    height: 44px;
    border-radius: $hty-radius;
  }
}
.content-row {
  display: flex;
  margin-bottom: 1.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  > * {
    width: 100%;

    .label {
      display: flex;
      justify-content: flex-start;
    }
  }

  > .right {
    margin-left: 1.5rem;
  }
}
.header {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.2;
  color: $hty-orange;
  text-align: center;
}
.content {
  width: 100%;
  margin: 1.5rem auto 0;
  font-style: normal;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 26px;
  letter-spacing: 0.2px;
  text-align: center;
}
.footer {
  display: flex;
  justify-content: center;

  > * {
    display: inline;
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 0.9rem;
    text-align: center;
    letter-spacing: 0.035rem;
  }

  :last-child {
    margin-left: 20px;
  }
}
@media (max-width: $hty-sm) {
  .footer {
    justify-content: center;
  }
}
</style>
