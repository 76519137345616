<template>
  <div class="modal-custom">
    <!-- todo: move to slots and components -->
    <!-- SP & R -->
    <template v-if="$store.getters.userInfo.role !== 'user'">
      <div class="close">
        <ButtonElement @click="$emit('cancel')" link grey>
          <XIcon />
        </ButtonElement>
      </div>
      <div class="header">
        {{ $t('serviceProvider.calendar.appointmentDetails') }}
      </div>
      <div class="content">
        <div class="list">
          <div
            v-for="(value, key) of appointmentData"
            :key="key"
            class="item"
            :class="{ 'd-none': key == 'note' }"
          >
            <span class="name">
              <template v-if="key == 'clients'">{{
                value ? value.length : ''
              }}</template>
              {{
                $t(
                  `${$store.getters.getTranslationKeyForAdmin}.calendar.${key}`
                )
              }}:
            </span>
            <span class="value" style="text-align: left">
              <template v-if="key == 'cost'"> {{ value | EUR }} </template>
              <template v-else-if="key == 'clients'">
                <span v-for="(client, i) in value" :key="i">
                  {{
                    client.name ||
                    client.firstName + ' ' + client.lastName ||
                    ''
                  }}<span v-if="i < value.length - 1" class="d-inline mr-1"
                    >,</span
                  >
                </span>
              </template>
              <template v-else>
                {{ value && value.name ? value.name : value }}
              </template>
            </span>
          </div>
        </div>
        <div v-if="appointmentData.note" class="item" style="margin-top: 20px">
          <span class="name">{{ $t('serviceProvider.calendar.notes') }}:</span>
          <span class="value" style="text-align: left">
            {{ appointmentData.note }}
          </span>
        </div>
      </div>

      <div
        v-if="$store.getters.isAdminRetailer && !isLimitReached"
        class="mt-3"
      >
        <label class="label"> Kunden hinzufügen </label>
        <div class="row">
          <div class="col">
            <SearchElement
              v-model="clientName"
              @input="handleServiceCall"
              @selected="handleClientSelection"
              :data="$store.getters.clients"
              :loading="$store.getters.loading"
              placeholder="Suche"
            />
          </div>
          <div class="col-auto">
            <ButtonElement
              @click="handleAddClient"
              orange
              :disabled="!clientName || $store.getters.loading"
            >
              + Hinzufügen
            </ButtonElement>
          </div>
        </div>
        <div>
          <ResponseAlert
            v-if="$store.getters.errorResponse.action == 'appointmentAddClient'"
            :response="$store.getters.errorResponse.data"
            class="mt-3"
          ></ResponseAlert>
        </div>
      </div>

      <small v-if="isLimitReached" class="d-inline-block mt-3 text-danger">
        Das Hinzufügen neuer Kunden ist nicht möglich, da das Limit erreicht
        ist.
      </small>

      <div class="footer form-row mt-4">
        <div class="col-auto">
          <ButtonElement @click="$emit('cancel')" orange-secondary>
            {{ $t('serviceProvider.calendar.cancel') }}
          </ButtonElement>
        </div>

        <div class="col">
          <ButtonElement @click="$emit('edit-appointment')" orange block>
            {{ $t('serviceProvider.calendar.edit') }}
          </ButtonElement>
        </div>

        <div class="col">
          <ButtonElement @click="$emit('cancel-appointment')" orange block>
            {{ $t('serviceProvider.calendar.cancelAppointment') }}
          </ButtonElement>
        </div>
        <!-- <div>
          <ButtonElement
            @click="$emit('delete-appointment')"
            color="red"
            style="color: white"
          >
            {{ $t('serviceProvider.calendar.deleteAppointment') }}
          </ButtonElement>
          <ButtonElement @click="$emit('edit-appointment')" orange-secondary>
            {{ $t('serviceProvider.calendar.edit') }}
          </ButtonElement>
        </div> -->
      </div>
    </template>

    <!-- Enduser -->
    <template v-else>
      <div class="close">
        <ButtonElement @click="$emit('cancel')" link grey>
          <XIcon />
        </ButtonElement>
      </div>
      <div class="header">
        {{ $t('serviceProvider.calendar.appointmentDetails') }}
      </div>

      <div class="content">
        <div class="list">
          <template v-for="(value, key) of appointmentData">
            <div :key="key" v-if="value" class="item">
              <span class="name">
                <template v-if="key == 'clients'">{{ value.length }}</template>
                {{
                  $t(
                    `${$store.getters.getTranslationKeyForAdmin}.calendar.${key}`
                  )
                }}:
              </span>
              <span class="value" style="text-align: left">
                <template v-if="key == 'cost'"> {{ value | EUR }} </template>
                <template v-else-if="key == 'clients'">
                  <span v-for="(client, i) in value" :key="i">
                    {{
                      client.name ||
                      client.firstName + ' ' + client.lastName ||
                      ''
                    }}<span v-if="i < value.length - 1" class="d-inline mr-1"
                      >,</span
                    >
                  </span>
                </template>
                <template v-else>
                  {{ value && value.name ? value.name : value }}
                </template>
              </span>
            </div>
          </template>
        </div>
      </div>

      <div class="footer form-row mt-4">
        <div class="col">
          <ButtonElement @click="$emit('cancel')" orange-secondary block>
            {{ $t('serviceProvider.calendar.cancel') }}
          </ButtonElement>
        </div>

        <div class="col">
          <ButtonElement
            @click="navigateEditAppointmentEnduser"
            orange
            block
            :disabled="!isAppointmentEditable"
          >
            {{ $t('serviceProvider.calendar.edit') }}
          </ButtonElement>
        </div>

        <div class="col">
          <ButtonElement
            @click="$emit('cancel-appointment')"
            :disabled="!isAppointmentEditable"
            orange
            block
          >
            {{ $t('serviceProvider.calendar.cancelAppointment') }}
          </ButtonElement>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ButtonElement from '../../FormControl/ButtonElement.vue';
import SearchElement from '../../Search/SearchElement.vue';
import { fullName } from '../../../utils/helpers';
import { debounce } from 'lodash';
import store from '../../../store';
import ResponseAlert from '@/components/Alerts/ResponseAlert';

export default {
  data() {
    return {
      client: null,
      clientName: '',
    };
  },
  computed: {
    isLimitReached() {
      return this.appointmentData.clients?.length >= this.appointmentData.limit;
    },
    isAppointmentInPast() {
      const apt = this.appointmentData;
      const aptDate = new Date(`${apt.date}T${apt.time.substr(0, 5)}:00`);
      return aptDate <= new Date();
    },
    isAppointmentEditable() {
      return !this.isAppointmentInPast && this.appointmentOptions.confirmed;
    },
  },
  created() {
    this.$store.commit('setErrorResponse', {});
    console.log('appointmentData', this.appointmentData);
  },
  methods: {
    handleClientSelection([user]) {
      if (this.appointmentData.clients.find(c => c.id == user.id)) {
        this.clientName = '';
        this.client = null;
        return;
      }

      this.clientName = fullName(user);
      this.client = user;
    },

    handleServiceCall: debounce(value => {
      store.dispatch('searchClients', { search: value });
    }, 500),

    async handleAddClient() {
      if (
        this.clientName &&
        this.appointmentData.clients.find(c => c.name == this.clientName)
      ) {
        this.clientName = '';
        this.client = null;
        return;
      }

      if (this.clientName != fullName(this.client)) {
        this.client = null;
      }

      await store
        .dispatch('addClientToAppointment', {
          appointment_id: this.appointmentOptions.id,
          client_id: this.client ? this.client.id : this.clientName,
        })
        .then(() => {
          // Add client to local appointment data
          this.appointmentData.clients.push(
            this.client || {
              name: this.clientName,
            }
          );

          // Reset inputs
          this.clientName = '';
          this.client = null;
        });
    },

    navigateEditAppointmentEnduser() {
      const apt = this.$store.getters.appointmentById;
      this.$store.commit('setShowAppointmentDetailsModal', false);

      if (apt.service) {
        this.$router.push({
          name: 'ServiceBookAppointment',
          params: {
            id: apt.companyId,
          },
          query: {
            appointment_id: apt.id,
          },
        });
      } else {
        this.$router.push({
          name: 'RetailerBookAppointment',
          params: {
            id: apt.companyId,
          },
          query: {
            appointment_id: apt.id,
          },
        });
      }
    },
  },
  components: {
    ButtonElement,
    SearchElement,
    ResponseAlert,
  },
  props: {
    appointmentData: {
      type: Object,
      default: () => ({}),
    },
    appointmentOptions: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-custom {
  max-width: 650px;
  position: relative;
  padding: 2rem;
}
.close {
  position: absolute;
  top: 10px;
  right: 20px;

  ::v-deep path {
    stroke: $hty-black;
  }
}
.name {
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2;
  color: $hty-orange;
  margin-right: 5px;
}
.value {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.2;
  color: $hty-black;
}
.list {
  > :first-child {
    margin-right: 1rem;
  }

  display: grid;
  grid-template-columns: 1fr auto;
  row-gap: 20px;
  // grid-template-rows: ;
  // flex-direction: column;
  // align-items: flex-start;
}
.item {
  display: flex;
  justify-content: flex-start;
  display: grid;
  grid-template-columns: auto auto;
  // margin: auto;
  // &:not(:first-child) {
  //   margin-top: $hty-margin-low;
  // }
}
.header {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.2;
  color: $hty-orange;
  text-align: center;
}
.content {
  width: 100%;
  margin: 1.5rem auto 0;
  font-style: normal;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 26px;
  letter-spacing: 0.2px;
  text-align: center;
}
// .footer {
//   > * {
//     display: flex;
//     justify-content: space-between;

//     > * {
//       display: inline;
//       width: 100%;
//       font-style: normal;
//       font-weight: bold;
//       font-size: 0.9rem;
//       text-align: center;
//       letter-spacing: 0.035rem;

//       &:last-child {
//         margin-left: 10px;
//       }
//     }
//   }
//   > :last-child {
//     &:last-child {
//       // margin-left: -10px;
//     }
//   }
// }
@media (max-width: $hty-sm) {
  .footer {
    justify-content: center;
  }
}
</style>
