<template>
  <div v-if="$store.getters.isAdminUserVerified" class="calendar-container">
    <CalendarAdminHeader
      v-if="$store.getters.userInfo.role === 'admin'"
      :vuecal="vuecal"
      :calendar-title="calendarTitle"
      :period="period"
      :view="view"
    />
    <CalendarEnduserHeader
      v-else
      :vuecal="vuecal"
      :calendar-title="calendarTitle"
      :period="period"
      :view="view"
      :selected-date="selectedDate"
    />
    <main class="router-view">
      <CalendarGrid
        v-show="view === 'grid'"
        :period="period"
        :appointments="$store.getters.appointmentsToCalendarEvents"
        :selected-date="selectedDate"
        @view-data="viewData = $event"
        @title="calendarTitle = $event"
        @vuecal-ref="vuecal = $event"
        @current-appointment="currentAppointment = $event"
      />
      <CalendarList
        v-show="view === 'list'"
        :appointments="$store.getters.appointmentsToCalendarEvents"
        :view-data="viewData"
        @current-appointment="currentAppointment = $event"
      />
    </main>
    <Modal
      :open="$store.getters.showAppointmentCreateModal"
      @backdropClick="$store.commit('setShowAppointmentCreateModal', false)"
      :custom-style="{ maxWidth: '450px' }"
    >
      <AppointmentFormModal
        @cancel="$store.commit('setShowAppointmentCreateModal', false)"
        @confirm="$store.dispatch('saveAppointment', $event)"
      />
    </Modal>
    <Modal
      :open="$store.getters.showAppointmentEditModal"
      @backdropClick="$store.commit('setShowAppointmentEditModal', false)"
      :custom-style="{ maxWidth: '450px' }"
    >
      <AppointmentFormModal
        @cancel="$store.commit('setShowAppointmentEditModal', false)"
        @confirm="
          $store.dispatch('editAppointment', {
            id: $event.id,
            payload: $event,
          })
        "
        :data="$store.getters.appointmentById"
      />
    </Modal>
    <Modal
      :open="$store.getters.showAppointmentDetailsModal"
      @backdropClick="$store.commit('setShowAppointmentDetailsModal', false)"
      :custom-style="{ maxWidth: '650px' }"
    >
      <AppointmentDetailsModal
        @cancel="$store.commit('setShowAppointmentDetailsModal', false)"
        @edit-appointment="
          handleAppointmentConfirmationModal('setShowAppointmentEditModal')
        "
        @cancel-appointment="
          handleAppointmentConfirmationModal('setShowAppointmentCancelModal')
        "
        @delete-appointment="
          handleAppointmentConfirmationModal('setShowAppointmentDeleteModal')
        "
        :appointment-data="$store.getters.appointmentByIdDetails"
        :appointment-options="$store.getters.appointmentByIdDetailsOptions"
      />
    </Modal>
    <Modal
      :open="$store.getters.showAppointmentCancelModal"
      @backdropClick="$store.commit('setShowAppointmentCancelModal', false)"
    >
      <ConfirmationModal
        @cancel="$store.commit('setShowAppointmentCancelModal', false)"
        @approve="handleApproveCancelAppointment"
        color-approve-button="orange"
        :text-approve-button="$t('enduser.calendar.confirm')"
      >
        <template #header>
          <span>
            {{ $t('serviceProvider.calendar.confirmCancel') }}
          </span>
        </template>
        <template #default>
          {{ $t('serviceProvider.calendar.confirmCancelAppointmentMsg') }}
        </template>
      </ConfirmationModal>
    </Modal>
    <Modal
      :open="$store.getters.showAppointmentDeleteModal"
      @backdropClick="$store.commit('setShowAppointmentDeleteModal', false)"
    >
      <ConfirmationModal
        @cancel="$store.commit('setShowAppointmentDeleteModal', false)"
        @approve="handleApproveDeleteAppointment"
        color-approve-button="orange"
        text-approve-button="Confirm"
      >
        <template #header>
          <span style="color: #e51313; text-align: center">
            {{ $t('serviceProvider.calendar.confirmDelete') }}
          </span>
        </template>
        <template #default>
          {{ $t('serviceProvider.calendar.confirmDeleteAppointmentMsg') }}
        </template>
      </ConfirmationModal>
    </Modal>
  </div>
  <VerificationPendingAlert v-else></VerificationPendingAlert>
</template>

<script>
import {
  computed,
  onMounted,
  ref,
  watch,
  watchEffect,
} from '@vue/composition-api';
import { isSameYear } from 'date-fns';
import store from '../../store';
import router from '../../router';
import ButtonElement from '../../components/FormControl/ButtonElement.vue';
import Modal from '../../components/Modal/Modal.vue';
import ConfirmationModal from '../../components/Modal/ConfirmationModal.vue';
import SelectElement from '../../components/FormControl/SelectElement/SelectElement.vue';
import PlusIcon from '../../components/Svgs/PlusIcon.vue';
import CalendarCarrotLeftIcon from '../../components/Svgs/Carrots/CalendarCarrotLeftIcon.vue';
import AppointmentFormModal from '../../components/Modal/Appointment/AppointmentFormModal.vue';
import CalendarGrid from './CalendarGrid.vue';
import CalendarList from './CalendarList.vue';
import AppointmentDetailsModal from '../../components/Modal/Appointment/AppointmentDetailsModal.vue';
import CalendarAdminHeader from './CalendarHeader/CalendarAdminHeader.vue';
import CalendarEnduserHeader from './CalendarHeader/CalendarEnduserHeader.vue';
import { getFullYearParams } from '../../utils/dateTimeHelpers';
import VerificationPendingAlert from '@/components/Alerts/VerificationPendingAlert';

export default {
  setup(props) {
    const viewData = ref({});
    const calendarTitle = ref('');
    const vuecal = ref(null);
    const currentAppointment = ref({});

    onMounted(() => {
      store.dispatch('fetchAppointments');

      store.dispatch('fetchCompanyLocations');
      store.dispatch('fetchAllStaffUsers');
      store.dispatch('fetchCompanyServices');
    });

    watch(
      () => props.sortBy,
      newSortBy => {
        store.dispatch('fetchAppointments', {
          sortBy: newSortBy,
          sort: 'asc',
        });
      }
    );

    const handleAppointmentConfirmationModal = mutation => {
      store.commit('setShowAppointmentDetailsModal', false);
      store.commit(mutation, true);
    };

    const handleApproveDeleteAppointment = async () => {
      await store.dispatch(
        'handleDeleteAppointment',
        currentAppointment.value.id
      );
      store.commit('setShowAppointmentDeleteModal', false);
    };
    const handleApproveCancelAppointment = async () => {
      await store.dispatch('handleCancelAppointment', {
        id: currentAppointment.value.id,
        companyId: currentAppointment.value.companyId,
      });
      store.commit('setShowAppointmentCancelModal', false);
    };

    return {
      viewData,
      calendarTitle,
      vuecal,
      currentAppointment,
      handleAppointmentConfirmationModal,
      handleApproveDeleteAppointment,
      handleApproveCancelAppointment,
    };
  },
  mounted() {
    this.openAppointmentFromQuery();
  },
  watch: {
    '$route.query.t': function () {
      this.openAppointmentFromQuery();
    },
  },
  methods: {
    openAppointmentFromQuery() {
      if (this.$route.query.appointmentId) {
        this.$store
          .dispatch('fetchAppointmentById', {
            appointmentId: this.$route.query.appointmentId,
            params: {
              company_id: this.$route.query.companyId,
            },
          })
          .then(() => {
            store.commit('setShowAppointmentDetailsModal', true);
          });
      }
    },
  },
  components: {
    CalendarEnduserHeader,
    CalendarAdminHeader,
    AppointmentDetailsModal,
    CalendarList,
    CalendarGrid,
    AppointmentFormModal,
    PlusIcon,
    SelectElement,
    ConfirmationModal,
    Modal,
    ButtonElement,
    CalendarCarrotLeftIcon,
    VerificationPendingAlert,
  },
  props: {
    view: {
      type: String,
      default: 'grid',
    },
    period: {
      type: String,
      default: 'day',
    },
    selectedDate: {
      type: String,
      required: true,
    },
    sortBy: {
      type: String,
      required: true,
    },
    sort: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.router-view {
  width: 100%;
}
.calendar-container {
  margin: 1.25rem 1.5rem;
}
</style>
