<template>
  <span class="email">
    <span class="text">
      {{ email }}
    </span>
    <span class="icon">
      <ButtonElement @click="$emit('removeRecipient', email)" link>
        <XIcon width="10px" height="10px" />
      </ButtonElement>
    </span>
  </span>
</template>

<script>
import XIcon from '../../Svgs/XIcon.vue';
import ButtonElement from '../ButtonElement.vue';

export default {
  components: {
    XIcon,
    ButtonElement,
  },
  props: {
    email: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.email {
  background: rgba(25, 118, 210, 0.1);
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  padding: 5px 8px;
  margin: 4px;
}
.text {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: $hty-black;
}
.icon {
  display: flex;
  align-items: center;
  margin-left: 5px;

  ::v-deep path {
    stroke: $hty-black;
  }
}
</style>
