var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.appointments.length > 0)?_c('div',{staticClass:"calendar-list-container"},[(_vm.viewDataToTable.length)?[(_vm.$store.getters.userInfo.role === 'user')?[(_vm.viewDataToTable.service_provider.length)?_c('div',{staticClass:"table-wrap"},[_c('div',{staticClass:"header"},[_vm._v(" "+_vm._s(_vm.$t('enduser.calendar.serviceProviderAppointment'))+" ")]),_c('TableDiv',{attrs:{"data":_vm.viewDataToTable.service_provider,"column-width":_vm.tableColumnWidth,"ignore-data":[
            'appointment',
            'appointmentData',
            'address',
            'companyname',
            'type' ],"row-validator":"!row.appointmentData.confirmed"},on:{"row-click":function($event){return _vm.handleAppointmentDetails($event)}},scopedSlots:_vm._u([{key:"cell-service",fn:function(ref){
          var value = ref.value;
return [_c('ButtonElement',{class:{ canceled: !value.active },attrs:{"link":""},on:{"click":function($event){return _vm.handleAppointmentDetails(value)}}},[_vm._v(" "+_vm._s(value.name)+" ")])]}},{key:"cell-cost",fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("EUR")(value))+" ")]}}],null,false,258453306)})],1):_vm._e(),(_vm.viewDataToTable.retailer.length)?_c('div',{staticClass:"table-wrap"},[_c('div',{staticClass:"header"},[_vm._v(" "+_vm._s(_vm.$t('enduser.calendar.retailerAppointment'))+" ")]),_c('TableDiv',{attrs:{"data":_vm.viewDataToTable.retailer,"ignore-data":[
            'appointment',
            'appointmentData',
            'service',
            'staff',
            'type',
            'cost' ],"row-validator":"!row.appointmentData.confirmed"},on:{"row-click":function($event){return _vm.handleAppointmentDetails($event)}}})],1):_vm._e()]:[_c('TableDiv',{attrs:{"data":_vm.viewDataToTable,"column-width":_vm.tableColumnWidth,"ignore-data":['appointment', 'appointmentData'],"row-validator":"!row.appointmentData.confirmed"},on:{"row-click":function($event){return _vm.handleAppointmentDetails($event)}},scopedSlots:_vm._u([{key:"cell-clients",fn:function(ref){
          var value = ref.value;
return [_c('span',{staticClass:"d-inline mr-1"},[_vm._v(" ("+_vm._s(value.length)+") ")]),_vm._l((value),function(client,i){return _c('span',{key:i},[_vm._v(" "+_vm._s(client.name || client.firstName + ' ' + client.lastName || '')),(i < value.length - 1)?_c('span',{staticClass:"d-inline mr-1"},[_vm._v(",")]):_vm._e()])})]}},{key:"cell-type",fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t('tables.data.' + value))+" ")]}},{key:"cell-cost",fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("EUR")(value))+" ")]}},{key:"cell-service",fn:function(ref){
          var cell = ref.cell;
          var value = ref.value;
return [_c('ButtonElement',{class:{ canceled: !value.active },attrs:{"link":""},on:{"click":function($event){return _vm.handleAppointmentDetails(cell)}}},[_vm._v(" "+_vm._s(value.name)+" ")])]}},{key:"cell-appointment",fn:function(ref){
          var cell = ref.cell;
          var value = ref.value;
return [_c('ButtonElement',{class:{ canceled: !value.active },attrs:{"link":""},on:{"click":function($event){return _vm.handleAppointmentDetails(cell)}}},[_vm._v(" "+_vm._s(value.name)+" ")])]}},{key:"cell-booking",fn:function(ref){
          var cell = ref.cell;
          var value = ref.value;
return [_c('ButtonElement',{class:{ canceled: !value.active },attrs:{"link":""},on:{"click":function($event){return _vm.handleAppointmentDetails(cell)}}},[_vm._v(" "+_vm._s(value.name)+" ")])]}}],null,false,3326331946)})]]:[_c('div',{staticClass:"calendar-info",staticStyle:{"border-radius":"10px"}},[_vm._v(" "+_vm._s(_vm.$t("serviceProvider.calendar.noAppointments"))+" ")])]],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }