<template>
  <div class="modal-custom pt-4">
    <div style="position: absolute; top: 0.5rem; right: 1rem">
      <ButtonElement @click="$emit('cancel')" link grey>
        <XIcon />
      </ButtonElement>
    </div>
    <div class="confirmation-modal__header px-3">
      <slot name="header">
        {{ $t('enduser.calendar.confirm') }}
      </slot>
    </div>
    <div class="confirmation-modal__content">
      <slot />
    </div>
    <div class="confirmation-modal__footer">
      <ButtonElement @click="$emit('cancel')" orange-secondary>
        {{ textCancelButton }}
      </ButtonElement>
      <ButtonElement @click="$emit('approve')" :[colorApproveButton]="true">
        {{ textApproveButton }}
      </ButtonElement>
    </div>
  </div>
</template>

<script>
import ButtonElement from '../FormControl/ButtonElement.vue';

export default {
  components: {
    ButtonElement,
  },
  props: {
    colorApproveButton: {
      type: String,
      default: '',
    },
    textApproveButton: {
      type: String,
      default: function () {
        return this.$t('serviceProvider.notification.yes');
      },
    },
    textCancelButton: {
      type: String,
      default: function () {
        return this.$t('serviceProvider.notification.no');
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.confirmation-modal__header {
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.4px;
}
.confirmation-modal__content {
  width: 100%;
  margin: 0 auto;
  padding: 30px 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 26px;
  letter-spacing: 0.2px;
  text-align: center;
}
.confirmation-modal__footer {
  display: flex;
  justify-content: flex-end;

  > * {
    width: 100px;
    font-style: normal;
    font-weight: bold;
    font-size: 0.9rem;
    text-align: center;
    letter-spacing: 0.035rem;
  }

  :last-child {
    margin-left: 20px;
  }
}
@media (max-width: $hty-sm) {
  .confirmation-modal__footer {
    justify-content: center;
  }
}
</style>
