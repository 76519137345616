<template>
  <div class="emails-input">
    <MultiInputItem
      v-for="email in recipients"
      :key="email"
      :email="email"
      @removeRecipient="$emit('removeRecipient', $event)"
    />
    <input
      class="input"
      type="text"
      :value="value"
      @input="handleAddChar($event.target.value)"
      @keypress="$emit('keypress', $event.key)"
      @keydown="$emit('keydown', $event.key)"
      @keyup="$emit('keyup', $event.key)"
      ref="emailsInput"
    />
    <OutsideClickContainer @outsideClick="isOpen = false" :active="isOpen">
      <DropdownElement v-if="isOpen" top="60px" width="100%">
        <div class="dropdown">
          <div v-if="loading" class="dropdown-title">Loading...</div>
          <div v-else>
            <div
              v-for="item of searchData"
              :key="item.id"
              class="dropdown-item"
              role="button"
              tabindex="0"
              @click="handleSelection(item)"
            >
              <div class="text">{{ `${item.firstName} ${item.lastName}` }}</div>
            </div>
          </div>
        </div>
      </DropdownElement>
    </OutsideClickContainer>
  </div>
</template>

<script>
import { ref, watchEffect } from '@vue/composition-api';
import MultiInputItem from './MultiInputItem.vue';
import OutsideClickContainer from '../../OutsideClickContainer/OutsideClickContainer.vue';
import DropdownElement from '../../Dropdown/DropdownElement.vue';

export default {
  setup(props, { emit }) {
    const isOpen = ref(false);
    const shouldClose = ref(false);

    watchEffect(() => {
      if (!shouldClose.value && props.value && props.searchData.length > 0) {
        isOpen.value = true;
      } else {
        isOpen.value = false;
      }
    });

    watchEffect(() => {
      console.log(`props.searchData`, props.searchData);
    });

    const handleSelection = user => {
      shouldClose.value = true;
      emit('selected', user);
    };

    const handleAddChar = value => {
      shouldClose.value = false;
      emit('input', value);
    };

    return {
      isOpen,
      handleSelection,
      handleAddChar,
    };
  },
  components: {
    DropdownElement,
    OutsideClickContainer,
    MultiInputItem,
  },
  props: {
    recipients: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: '',
    },
    focus: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    searchData: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    if (this.focus) this.focusInput('emailsInput');
  },
  computed: {},
  methods: {
    focusInput(inputRef) {
      this.$refs[inputRef].focus();
    },
    // handleInput(event) {
    //   this.$emit('input', event.target.value);
    //   // SM: we need to reset input value here otherwise ',' will stay after creating new element
    //   this.$refs.emailsInput.value = '';
    // },
  },
};
</script>

<style lang="scss" scoped>
.emails-input {
  border: $hty-border;
  border-radius: $hty-radius;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.input {
  width: 100%;
  height: 50px;
  border: white;
  border-radius: $hty-radius;
  outline: none;
  padding-left: 10px;
  font-weight: 300;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0.4px;

  @include hty-placeholder-color;
}

.dropdown {
  position: absolute;
  top: -10px;
  background-color: white;
  box-shadow: $hty-shadow;
  width: 100%;
}

.disabled {
  cursor: not-allowed;
}
</style>
