<template>
  <div class="search">
    <SearchIcon />
    <!-- v-model="searchInput" -->
    <InputElement
      :value="value"
      @input="handleAddChar"
      class="search-input"
      :focus="focus"
      :placeholder="placeholder"
    />
    <OutsideClickContainer @outsideClick="isOpen = false" :active="isOpen">
      <DropdownElement v-if="isOpen" top="60px" width="100%">
        <div class="dropdown">
          <div v-if="loading" class="dropdown-title">Loading...</div>
          <div v-else>
            <div
              v-for="item of data"
              :key="item.id"
              class="dropdown-item"
              role="button"
              tabindex="0"
              @click="handleSelection(item)"
            >
              <div class="text">{{ `${item.firstName} ${item.lastName}` }}</div>
            </div>
          </div>
        </div>
      </DropdownElement>
    </OutsideClickContainer>
  </div>
</template>

<script>
import { ref, toRef, watchEffect } from '@vue/composition-api';
import InputElement from '../FormControl/InputElement.vue';
import SearchIcon from '../Svgs/SearchIcon.vue';
import OutsideClickContainer from '../OutsideClickContainer/OutsideClickContainer.vue';
import DropdownElement from '../Dropdown/DropdownElement.vue';

export default {
  setup(props, { emit }) {
    const searchInput = ref('');
    const isOpen = ref(false);
    const selectedValues = ref([]);
    const shouldClose = ref(false);

    watchEffect(() => {
      if (!shouldClose.value && props.value && props.data.length > 0) {
        isOpen.value = true;
      } else {
        isOpen.value = false;
      }
    });

    const handleSelection = user => {
      shouldClose.value = true;
      // selectedValues.value = [...selectedValues.value, user];
      selectedValues.value = [user];
      emit('selected', selectedValues.value);
    };

    const handleAddChar = value => {
      shouldClose.value = false;
      emit('input', value);
    };

    return {
      searchInput,
      isOpen,
      handleSelection,
      handleAddChar,
    };
  },
  components: {
    DropdownElement,
    OutsideClickContainer,
    SearchIcon,
    InputElement,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Search',
    },
    data: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  position: relative;
  width: 100%;
  // margin-left: 20px;

  > :first-child {
    position: absolute;
    left: 1rem;
    top: 0.7rem;
  }

  > .search-input {
    ::v-deep input {
      padding-left: 3rem;
    }
  }
}
</style>
