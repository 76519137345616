<template>
  <!-- important not to render vuecal before appointments is populated, as we need ready event to already have data
  otherwise loading list view first will be empty -->
  <div v-if="$store.getters.appointmentsLoaded" class="grid-container">
    <VueCal
      ref="vuecalRef"
      :disable-views="['years']"
      :events="appointments"
      :on-event-click="handleEventClick"
      :on-event-dblclick="handleEventDoubleClick"
      events-on-month-view="short"
      events-count-on-year-view
      hide-view-selector
      locale="de"
      :selected-date="selectedDate"
      :time-cell-height="144"
      :transitions="false"
      :active-view.sync="period"
      @view-change="updateListView($event, 'view-change')"
      @ready="updateListView($event, 'ready')"
      @event-create="eventAdd($event)"
      @event-change="eventAdd($event)"
    >
      <template #title="{ title }"> {{ sendTitle(title) }} </template>
    </VueCal>
  </div>
</template>

<script>
import VueCal from 'vue-cal';
import 'vue-cal/dist/i18n/de.js';
import 'vue-cal/dist/vuecal.css';
import { onMounted, ref, watchEffect } from '@vue/composition-api';
import router from '../../router';
import store from '../../store';

export default {
  setup(props, { emit }) {
    const vuecalRef = ref(null);

    const handleEventClick = async (event, e) => {
      // console.log('event, e :>> ', event, e);
      e.stopPropagation();

      // Trick to detect if click was on scroll bar
      if (e.detail == 0) {
        return;
      }

      emit('current-appointment', event.appointment);
      await store.dispatch('fetchAppointmentById', {
        appointmentId: event.appointmentId,
        params: {
          company_id: event.appointment.companyId,
        },
      });
      store.commit('setShowAppointmentDetailsModal', true);
    };

    const handleEventDoubleClick = (event, e) => {
      // console.log('event, e :>> ', event, e);
      e.stopPropagation();
    };

    watchEffect(() => {
      console.log(`props.appointments`, props.appointments);
    });

    watchEffect(() => {
      emit('vuecal-ref', vuecalRef.value);
    });

    const updateListView = (event, type) => {
      console.log('event vucal :>> ', event, type);
      emit('view-data', event);
    };

    const eventAdd = event => {
      console.log(`eventAdd`, event);
      //SM: ideally we should listen when event is added or removed to an event array that is passed to vuecal
      // so we could update array here and not have ugly hack in general actions to manually set empty array
      // for appointemnts, but I'm not sure if vuecal emits such an event when we change event array manually
    };

    const sendTitle = title => {
      emit('title', title);
    };

    return {
      handleEventClick,
      handleEventDoubleClick,
      vuecalRef,
      updateListView,
      sendTitle,
      eventAdd,
    };
  },
  components: {
    VueCal,
  },
  props: {
    appointments: {
      type: Array,
      default: () => [],
    },
    period: {
      type: String,
      default: 'day',
    },
    selectedDate: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-container {
  margin-top: $hty-margin-low;
  height: calc(100vh - 270px);

  ::v-deep .vuecal__title-bar {
    display: none;

    position: relative;
    // bottom: 60px;
  }
  ::v-deep .vuecal__event {
    background-color: rgba(229, 132, 19, 0.15);
    border-left: 2px solid $hty-orange;

    > .vuecal__event-title {
      color: $hty-orange;
      margin-top: 10px;
    }
    > .vuecal__event-time {
      color: $hty-orange;
      margin-top: 10px;
    }
  }
  ::v-deep .vuecal__event.active {
    background-color: rgba(229, 132, 19, 0.15);
    border-left: 2px solid $hty-orange;

    > .vuecal__event-title {
      color: $hty-orange;
      margin-top: 10px;
    }
    > .vuecal__event-time {
      color: $hty-orange;
      margin-top: 10px;
    }
  }
  ::v-deep .vuecal__event.canceled {
    background-color: $hty-grey;
    border-left: 2px solid $hty-medium-grey;

    > .vuecal__event-title {
      color: $hty-medium-grey;
      margin-top: 10px;
    }
    > .vuecal__event-time {
      color: $hty-medium-grey;
      margin-top: 10px;
    }
  }
}
.appointment-details {
  position: absolute;
  left: 50px;
}
</style>
