<template>
  <div v-if="appointments.length > 0" class="calendar-list-container">
    <template v-if="viewDataToTable.length">
      <template v-if="$store.getters.userInfo.role === 'user'">
        <div class="table-wrap" v-if="viewDataToTable.service_provider.length">
          <div class="header">
            {{ $t('enduser.calendar.serviceProviderAppointment') }}
          </div>
          <TableDiv
            :data="viewDataToTable.service_provider"
            :column-width="tableColumnWidth"
            :ignore-data="[
              'appointment',
              'appointmentData',
              'address',
              'companyname',
              'type',
            ]"
            row-validator="!row.appointmentData.confirmed"
            @row-click="handleAppointmentDetails($event)"
          >
            <template #cell-service="{ value }">
              <ButtonElement
                @click="handleAppointmentDetails(value)"
                link
                :class="{ canceled: !value.active }"
              >
                {{ value.name }}
              </ButtonElement>
            </template>
            <template #cell-cost="{ value }">
              {{ value | EUR }}
            </template>
          </TableDiv>
        </div>
        <div class="table-wrap" v-if="viewDataToTable.retailer.length">
          <div class="header">
            {{ $t('enduser.calendar.retailerAppointment') }}
          </div>
          <TableDiv
            :data="viewDataToTable.retailer"
            :ignore-data="[
              'appointment',
              'appointmentData',
              'service',
              'staff',
              'type',
              'cost',
            ]"
            row-validator="!row.appointmentData.confirmed"
            @row-click="handleAppointmentDetails($event)"
          >
          </TableDiv>
        </div>
      </template>
      <template v-else>
        <TableDiv
          :data="viewDataToTable"
          :column-width="tableColumnWidth"
          :ignore-data="['appointment', 'appointmentData']"
          row-validator="!row.appointmentData.confirmed"
          @row-click="handleAppointmentDetails($event)"
        >
          <template #cell-clients="{ value }">
            <span class="d-inline mr-1"> ({{ value.length }}) </span>
            <span v-for="(client, i) in value" :key="i">
              {{ client.name || client.firstName + ' ' + client.lastName || ''
              }}<span v-if="i < value.length - 1" class="d-inline mr-1">,</span>
            </span>
          </template>
          <template #cell-type="{ value }">
            {{ $t('tables.data.' + value) }}
          </template>
          <template #cell-cost="{ value }">
            {{ value | EUR }}
          </template>
          <!-- Admin SP -->
          <template #cell-service="{ cell, value }">
            <ButtonElement
              @click="handleAppointmentDetails(cell)"
              link
              :class="{ canceled: !value.active }"
            >
              {{ value.name }}
            </ButtonElement>
          </template>
          <!-- Admin R -->
          <template #cell-appointment="{ cell, value }">
            <ButtonElement
              @click="handleAppointmentDetails(cell)"
              link
              :class="{ canceled: !value.active }"
            >
              {{ value.name }}
            </ButtonElement>
          </template>
          <!-- End user -->
          <template #cell-booking="{ cell, value }">
            <ButtonElement
              @click="handleAppointmentDetails(cell)"
              link
              :class="{ canceled: !value.active }"
            >
              {{ value.name }}
            </ButtonElement>
          </template>
        </TableDiv>
      </template>
    </template>
    <template v-else>
      <div class="calendar-info" style="border-radius: 10px">
        {{ $t(`serviceProvider.calendar.noAppointments`) }}
      </div>
    </template>
  </div>
</template>

<script>
import { computed, ref, watchEffect } from '@vue/composition-api';
import TableDiv from '../../components/Table/TableDiv.vue';
import ButtonElement from '../../components/FormControl/ButtonElement.vue';
import store from '../../store';

export default {
  setup(props, { emit }) {
    const viewDataToTable = computed(() => {
      if (store.getters.isAdminServiceProvider) {
        return (
          props.viewData.events?.map(item => ({
            appointmentData: item.appointment,
            service: {
              name: item.appointment.serviceData?.name,
              id: item.appointment.id,
              active: item.appointment.confirmed,
            },
            date: item.appointment.dateStart,
            time: `${item.appointment.timeStart?.slice(
              0,
              5
            )} - ${item.appointment.timeEnd?.slice(0, 5)}`,
            staff: `${item.appointment.staffUser?.firstName} ${item.appointment.staffUser?.lastName}`,
            client: item.appointment.clients?.firstName
              ? `${item.appointment.clients?.firstName} ${item.appointment.clients?.lastName}`
              : item.appointment.clientId,
            cost: item.appointment.serviceData?.price,
          })) || []
        );
      } else if (store.getters.isAdminRetailer) {
        return (
          props.viewData.events?.map(item => ({
            appointmentData: item.appointment,
            appointment: {
              name: item.appointment.companyUser?.companyName,
              id: item.appointment.id,
              active: item.appointment.confirmed,
            },
            date: item.appointment.dateStart,
            time: `${item.appointment.timeStart?.slice(
              0,
              5
            )} - ${item.appointment.timeEnd?.slice(0, 5)}`,
            // client:
            //   item.appointment.clients?.length > 0 &&
            //   item.appointment.clients[0].firstName
            //     ? `${item.appointment.clients[0].firstName} ${item.appointment.clients[0].lastName}`
            //     : item.appointment.clients?.length > 0 &&
            //       item.appointment.clients[0].name
            //     ? item.appointment.clients[0].name
            //     : '-',
            clients: item.appointment.clients || [],
            limit: item.appointment.limit,
          })) || []
        );
      } else if (store.getters.userInfo.role === 'user') {
        let byType = {
          retailer: [],
          service_provider: [],
          length: 0,
        };

        props.viewData.events?.forEach(item => {
          const companyType = item.appointment.companyUser.type;
          byType[companyType].push({
            appointmentData: item.appointment,
            companyname: item.appointment.companyUser.companyName,
            address: item.appointment.companyUser.address,
            service:
              companyType === 'service_provider'
                ? {
                    name: item.appointment.serviceData?.name,
                    id: item.appointment.id,
                    active: item.appointment.confirmed,
                  }
                : '',
            date: item.appointment.dateStart,
            time: `${item.appointment.timeStart?.slice(
              0,
              5
            )} - ${item.appointment.timeEnd?.slice(0, 5)}`,
            staff:
              companyType === 'service_provider'
                ? `${item.appointment.staffUser?.firstName} ${item.appointment.staffUser?.lastName}`
                : '-',
            type:
              companyType === 'service_provider'
                ? item.appointment.serviceData?.priceType
                : '-',
            cost:
              companyType === 'service_provider'
                ? item.appointment.serviceData?.price
                : '-',
          });
        });
        byType.length = byType.retailer.length + byType.service_provider.length;
        return byType;
      }
    });

    watchEffect(() => {
      console.log('props.viewData :>> ', props.viewData);
      console.log('viewDataToTable :>> ', viewDataToTable.value);
    });

    const handleAppointmentDetails = async apt => {
      if (!apt) {
        return;
      }

      emit('current-appointment', apt.appointmentData);
      await store.dispatch('fetchAppointmentById', {
        appointmentId: apt.appointmentData.id,
        params: {
          company_id: apt.appointmentData.companyId,
        },
      });
      store.commit('setShowAppointmentDetailsModal', true);
    };

    return {
      handleAppointmentDetails,
      viewDataToTable,
    };
  },
  data() {
    return {
      tableColumnWidth: [
        { key: 'date', width: '150px', fixed: true },
        { key: 'time', width: '250px', fixed: true },
        { key: 'clients', width: '500px', fixed: false },
        { key: 'limit', width: '100px', fixed: true },
        { key: 'companyname', width: '400px', fixed: true },
        { key: 'address', width: '400px', fixed: true },
      ],
    };
  },
  components: {
    ButtonElement,
    TableDiv,
  },
  props: {
    appointments: {
      type: Array,
      default: () => [],
    },
    viewData: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-list-container {
  margin-top: $hty-margin-low;

  height: calc(100vh - 270px);
}
.calendar-info {
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 20px;
  height: 40px;
  color: #e58413;
  background: #fbeee2;
}

.table-wrap {
  padding-bottom: 30px;

  .header {
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.2;
    color: #505050;
    text-align: center;
    padding-bottom: 10px;
  }
}
</style>
